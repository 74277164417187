<template>
    <Suspense>
        <template #fallback>
            <Overlay/>
        </template>
        <OBaseGrid v-bind="$attrs" ref="gridRef">
            <template v-if="$slots.systemcolumn" #systemcolumn>
                <slot name="systemcolumn"></slot>
            </template>
            <template v-if="$slots.default" #default>
                <slot name="default"></slot>
            </template>
            <template v-if="$slots.actioncolumn" #actioncolumn>
                <slot name="actioncolumn"></slot>
            </template>
            <template v-if="$slots.header" #header>
                <slot name="header"></slot>
            </template>
            <template v-if="$slots.datalist" #datalist>
                <slot name="datalist"></slot>
            </template>
            <template v-if="$slots.footer" #footer>
                <slot name="footer"></slot>
            </template>
            <template v-if="$slots.bodybottom" #bodybottom>
                <slot name="bodybottom"></slot>
            </template>
            <template v-if="$slots.gridbodymenutabs" #gridbodymenutabs>
                <slot name="gridbodymenutabs"></slot>
            </template>
            <template v-if="$slots.gridsidemenu" #gridsidemenu>
                <slot name="gridsidemenu"></slot>
            </template>
            <template v-if="$slots.statusbar" #statusbar>
                <slot name="statusbar"></slot>
            </template>
        </OBaseGrid>
    </Suspense>
</template>

<script setup lang="ts">
import { Suspense } from 'vue';
import Overlay from 'o365.vue.components.Overlay.vue';
import OBaseGrid from 'o365.vue.components.Grid.BaseGrid.vue';
import { ref } from 'vue';

const gridRef = ref(null);
defineExpose({gridRef});
</script>